
export const MOSTRAR_ALERTA = 'MOSTRAR_ALERTA';
export const OCULTAR_ALERTA = 'OCULTAR_ALERTA';

export const REGISTRO_EXITOSO = 'REGISTRO_EXITOSO';
export const REGISTRO_ERROR = 'REGISTRO_ERROR';
export const OBTENER_USUARIO = 'OBTENER_USUARIO';
export const LOGIN_EXITOSO = 'LOGIN_EXITOSO';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const CERRAR_SESION = 'CERRAR_SESION';

export const LIBRO_ACTUAL = 'LIBRO_ACTUAL';
export const ACTIVIDAD_LIBRO = 'ACTIVIDAD_LIBRO';
export const VISIBLE_BOOKMAIN = 'VISIBLE_BOOKMAIN';

import React from 'react';
import LessonHome from '../LessonHome';

const Lesson3 = (props) => {

    return ( 
        <LessonHome
            {...props} 
        />        
     );
}
 
export default Lesson3;
import React from 'react';
import LessonHome from '../LessonHome';

const Lesson11 = (props) => {

    return ( 
        <LessonHome
            {...props}
        />        
     );
}
 
export default Lesson11;